import { t } from 'i18next'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { INTENTS } from '@/actions/intents'
import { EscrowTokenSelector } from '@/components/asset/escrow-token-selector'
import Button from '@/components/button'
import { Form } from '@/components/form'
import { Group } from '@/components/group'
import Input from '@/components/input'
import { ListItem } from '@/components/list-item'
import { ROUTES } from '@/constants/routes'
import { BannerManager } from '@/contexts/banner'
import { ToastManager } from '@/contexts/toast'
import { formatNumber } from '@/helpers/formatValue'
import { useCustodians } from '@/hooks/queries/useCustodians'
import { ActionResponse } from '@/types/actions'

import { Drawer } from '../..'
import { BaseProps } from '../types'

export interface Props extends BaseProps {}
export const NewEscrowDrawer = (props: Props) => {
  const navigate = useNavigate()
  const OrgId = '27b91c1c-81ef-43c5-bd39-41565bcbd912' // << hardcoded AR org Id
  const [_submitting, setSubmitting] = useState<INTENTS | null>(null)
  const { custodians } = useCustodians(OrgId)

  // Clear the submitting flag
  const clearSubmitting = () => setSubmitting(null)

  // On form submission
  const onSubmit = (intent: INTENTS) => setSubmitting(intent)

  // On form exception
  const onException = (actionRes: ActionResponse<string>) => {
    BannerManager.showBanner({
      variant: 'page',
      type: 'error',
      title: actionRes.message ?? t('something_went_wrong'),
    })
    props.onClose()
  }

  // On form submit successfully
  const onSuccess = (actionRes: ActionResponse<string>) => {
    ToastManager.showToast({
      type: 'success',
      text: actionRes.message ?? t('action_successful'),
    })
    navigate(
      ROUTES.ADMIN.ESCROW.DETAIL.replace(':escrow_id', actionRes.data ?? '')
    )
    props.onClose()
  }

  return (
    <Drawer
      title={t('new_escrow_drawer_title')}
      description={t('new_escrow_drawer_description')}
      visible={props.visible}
      onClose={props.onClose}
    >
      <Form<string, INTENTS>
        type={'other'}
        testId={'create-escrow-form'}
        buttons={[
          <Button.Basic
            key={'btn_create_escrow'}
            label={t('create_escrow')}
            state={
              _submitting === INTENTS.CREATE_ESCROW
                ? 'loading'
                : _submitting !== null || (custodians ?? []).length <= 0
                  ? 'disabled'
                  : 'default'
            }
            withAttributes={{
              type: 'submit',
              name: 'intent',
              value: INTENTS.CREATE_ESCROW,
            }}
          />,
        ]}
        onSubmit={onSubmit}
        onSuccess={onSuccess}
        onException={onException}
        onFailed={clearSubmitting}
        onUnknownResponse={clearSubmitting}
        containerClassName={'gap-[48px]'}
      >
        <Group label={t('details')}>
          <Input.Text
            id="escrow_name"
            name="escrow_name"
            label={t('escrow_name')}
            hint={t('create_escrow_name_bottom_text')}
            placeholder={t('create_escrow_name_placeholder')}
            maxLength={35}
          />
        </Group>

        <Group label={t('parties')}>
          <div className={'flex flex-row gap-[24px]'}>
            <Input.Text
              id="party_a"
              name="party_a"
              label={t('create_escrow_party_a_label')}
              hint={t('create_escrow_party_a_bottom_text')}
              placeholder={t('create_escrow_party_a_placeholder')}
            />
            <Input.Dropdown
              name="party_a_min_approvals"
              label={t('approvals')}
              defaultValue={'1'}
              items={Array.from({ length: 10 }).map((_a, i) => (
                <ListItem
                  key={i + 1}
                  value={(i + 1).toString()}
                  title={formatNumber(i + 1, { minimumIntegerDigits: 2 })}
                  className={'pl-2 pr-2'}
                />
              ))}
              className={'max-w-[6rem] tablet:max-w-[7rem]'}
            />
          </div>

          <div className={'flex flex-row gap-[24px]'}>
            <Input.Text
              id="party_b"
              name="party_b"
              label={t('create_escrow_party_b_label')}
              hint={t('create_escrow_party_b_bottom_text')}
              placeholder={t('create_escrow_party_b_placeholder')}
            />
            <Input.Dropdown
              name="party_b_min_approvals"
              label={t('approvals')}
              defaultValue={'1'}
              items={Array.from({ length: 10 }).map((_a, i) => (
                <ListItem
                  key={i + 1}
                  value={(i + 1).toString()}
                  title={formatNumber(i + 1, { minimumIntegerDigits: 2 })}
                  className={'pl-2 pr-2'}
                />
              ))}
              className={'max-w-[6rem] tablet:max-w-[7rem]'}
            />
          </div>
        </Group>
        {(custodians ?? []).length > 0 && (
          <EscrowTokenSelector name={'escrow_assets'} organisationId={OrgId} />
        )}
      </Form>
    </Drawer>
  )
}
