import { t } from 'i18next'
import { ActionFunctionArgs } from 'react-router-dom'

import { AddAssetDocument } from '@/actions/add-asset-document'
import { ExtractErrorFrom } from '@/helpers/extractErrorFrom'
import { ActionResponse } from '@/types/actions'

import { AddEscrowDestinationAddress } from './add-escrow-destination-address'
import { AddOperationDocument } from './add-operation-document'
import { AddWorkspaceMember } from './add-workspace-member'
import { ArchiveCase } from './archive-case'
import { Authenticate } from './authenticate'
import { CaseAddExistingAssets } from './case-add-existing-assets'
import { CreateAsset } from './create-asset'
import { CreateAssetNote } from './create-asset-note'
import { CreateCase } from './create-case'
import { CreateCustodian } from './create-custodian'
import { CreateDefendant } from './create-defendant'
import { CreateEscrow } from './create-escrow'
import { CreateEscrowFee } from './create-escrow-fee'
import { CreateEscrowUser } from './create-escrow-user'
import { CreateOrganization } from './create-organization'
import { CreateSuperAdminUser } from './create-super-admin-user'
import { CreateSupportedAsset } from './create-supported-asset'
import { CreateUser } from './create-user'
import { CreateWorkspace } from './create-workspace'
import { DeleteAssetNote } from './delete-asset-note'
import { DeleteDefendant } from './delete-defendant'
import { DeleteEscrow } from './delete-escrow'
import { DeleteEscrowUser } from './delete-escrow-user'
import { DeleteUser } from './delete-user'
import { EditEscrowApprovers } from './edit_escrow_approvers'
import { EditEscrowFee } from './edit_escrow_fee'
import { EditAsset } from './edit-asset'
import { EditAssetNote } from './edit-asset-note'
import { EditCase } from './edit-case'
import { EditDefendant } from './edit-defendant'
import { EditEscrow } from './edit-escrow'
import { EditEscrowUser } from './edit-escrow-user'
import { EditOrganization } from './edit-organization'
import { EditSuperAdminUser } from './edit-super-admin-user'
import { EditUser } from './edit-user'
import { EditUserLanguage } from './edit-user-language'
import { EscrowMarkReady } from './escrow-mark-ready'
import { EscrowRejectDocuments } from './escrow-reject-documents'
import { EscrowSubmitForApproval } from './escrow-submit-for-approval'
import { GenerateReport } from './generate-report'
import { ImportAssets } from './import-assets'
import { INTENTS } from './intents'
import { ProgressEscrowFee } from './progress_escrow_fee'
import { RecoverPassword } from './recover-password'
import { RejectEscrowFee } from './reject_escrow_fee'
import { ResetPassword } from './reset-password'
import { RestoreCase } from './restore-case'
import { setNewPasswordAction } from './set-new-password'
import { ShareAsset } from './share-asset'
import { TransferAsset } from './transfer-asset'
import { updateUserAction } from './update-user/update-user'
import { VerifyPasscode } from './verify-passcode'

export const SubmitAction = async (
  intent: INTENTS,
  data: { [key: string]: string }
): Promise<Response | ActionResponse<unknown>> => {
  // Create the form data to submit into the action
  const form = new FormData()
  form.append('intent', intent)
  for (const key in data) {
    form.append(key, data[key])
  }

  // Call the action
  return await Action({
    request: new Request('', {
      method: 'POST',
      body: form,
    }),
    params: {},
  })
}

export const Action = async ({
  request,
}: ActionFunctionArgs): Promise<Response | ActionResponse<unknown>> => {
  try {
    const formData = await request.formData()
    const intent = formData.get('intent') as string

    switch (intent) {
      case INTENTS.AUTHENTICATE:
        return await Authenticate(formData)

      case INTENTS.RECOVER_PASSWORD:
        return await RecoverPassword(formData)

      case INTENTS.RESET_PASSWORD:
        return await ResetPassword(formData)

      case INTENTS.VERIFY_PASSCODE:
        return await VerifyPasscode(formData)

      case INTENTS.CREATE_WORKSPACE:
        return await CreateWorkspace(formData)

      case INTENTS.EDIT_WORKSPACE:
        throw new Error('Not yet available')

      case INTENTS.DELETE_WORKSPACE:
        throw new Error('Not yet available')

      case INTENTS.CREATE_CASE:
        return await CreateCase(formData)

      case INTENTS.EDIT_CASE:
        return await EditCase(formData)

      case INTENTS.CASE_ADD_EXISTING_ASSETS:
        return await CaseAddExistingAssets(formData)

      case INTENTS.ARCHIVE_CASE:
        return await ArchiveCase(formData)

      case INTENTS.RESTORE_CASE:
        return await RestoreCase(formData)

      case INTENTS.DELETE_ARCHIVED_CASE:
        throw new Error('Not yet available')

      case INTENTS.ADD_OPERATION_DOCUMENT:
        return await AddOperationDocument(formData)

      case INTENTS.CREATE_ASSET:
        // Needs updating to new Form Errors
        return await CreateAsset(formData)

      case INTENTS.EDIT_ASSET:
        // Needs updating to new Form Errors
        return await EditAsset(formData)

      case INTENTS.CREATE_ASSET_NOTE:
        return await CreateAssetNote(formData)

      case INTENTS.EDIT_ASSET_NOTE:
        return await EditAssetNote(formData)

      case INTENTS.DELETE_ASSET_NOTE:
        return await DeleteAssetNote(formData)

      case INTENTS.GENERATE_REPORT:
        return await GenerateReport(formData)

      case INTENTS.CREATE_DEFENDANT:
        return await CreateDefendant(formData)

      case INTENTS.ADD_ASSET_DOCUMENT:
        return await AddAssetDocument(formData)

      case INTENTS.EDIT_DEFENDANT:
        return await EditDefendant(formData)

      case INTENTS.DELETE_DEFENDANT:
        return await DeleteDefendant(formData)

      case INTENTS.CREATE_USER:
        return await CreateUser(formData)

      case INTENTS.EDIT_USER:
        return await EditUser(formData)

      case INTENTS.EDIT_USER_LANGUAGE:
        return await EditUserLanguage(formData)

      case INTENTS.DELETE_USER:
        return await DeleteUser(formData)

      case INTENTS.ADD_WORKSPACE_MEMBER:
        return await AddWorkspaceMember(formData)

      case INTENTS.CREATE_ORGANIZATION:
        return await CreateOrganization(formData)

      case INTENTS.EDIT_ORGANIZATION:
        return await EditOrganization(formData)

      case INTENTS.ADD_CUSTODIAN:
        return await CreateCustodian(formData)

      case INTENTS.ADD_SUPPORTED_ASSET:
        return await CreateSupportedAsset(formData)

      case INTENTS.SHARE_ASSET:
        return await ShareAsset(formData)

      case INTENTS.TRANSFER_ASSET:
        return await TransferAsset(formData)

      case INTENTS.CREATE_ESCROW:
        return await CreateEscrow(formData)

      case INTENTS.ESCROW_ADD_BLOCKCHAIN_ADDRESS:
        return await AddEscrowDestinationAddress(formData)

      case INTENTS.ESCROW_EDIT_APPROVERS:
        return await EditEscrowApprovers(formData)

      case INTENTS.ESCROW_PROGRESS_FEE:
        return await ProgressEscrowFee(formData)

      case INTENTS.ESCROW_REJECT_FEE:
        return await RejectEscrowFee(formData)

      case INTENTS.ESCROW_CREATE_FEE:
        return await CreateEscrowFee(formData)

      case INTENTS.ESCROW_EDIT_FEE:
        return await EditEscrowFee(formData)

      case INTENTS.EDIT_ESCROW:
        return await EditEscrow()

      case INTENTS.ESCROW_MARK_READY:
        return await EscrowMarkReady()

      case INTENTS.DELETE_ESCROW:
        return await DeleteEscrow(formData)

      case INTENTS.CREATE_ESCROW_USER:
        return await CreateEscrowUser(formData)

      case INTENTS.EDIT_ESCROW_USER:
        return await EditEscrowUser(formData)

      case INTENTS.DELETE_ESCROW_USER:
        return await DeleteEscrowUser(formData)

      case INTENTS.ESCROW_SUBMIT_FOR_APPROVAL:
        return await EscrowSubmitForApproval(formData)

      case INTENTS.ESCROW_REJECT_DOCUMENTS:
        return await EscrowRejectDocuments(formData)

      case INTENTS.CREATE_SUPER_ADMIN_USER:
        return await CreateSuperAdminUser(formData)

      case INTENTS.EDIT_SUPER_ADMIN_USER:
        return await EditSuperAdminUser(formData)

      case INTENTS.UPDATE_USER:
        return await updateUserAction(formData)

      case INTENTS.SET_NEW_PASSWORD:
        return await setNewPasswordAction(formData)

      case INTENTS.IMPORT_ASSETS:
        return await ImportAssets(formData)

      default:
        throw new Error('Invalid intent')
    }
  } catch (error: unknown) {
    console.error(error)
    return {
      error: false,
      exception: true,
      completion: 'NOT_COMPLETE',
      message: ExtractErrorFrom(error) ?? t('something_went_wrong'),
    }
  }
}
