import { t } from 'i18next'

import { deleteEscrow } from '@/api/escrows/v1'
import { REGEX } from '@/constants/regex'
import { FormErrorsManager } from '@/contexts/formErrors'
import { IsNot } from '@/helpers/test'
import { ActionResponse } from '@/types/actions'

export const DeleteEscrow = async (
  formData: FormData
): Promise<ActionResponse<boolean>> => {
  console.log(formData)
  const escrowId = formData.get('escrow_id')?.toString()

  // Validate the fields
  if (IsNot(escrowId, REGEX.IS_ANY)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: '',
        error: t('invalid_escrow_id'),
      },
    ])
  }

  // Make the call to delete the escrow
  const res = await deleteEscrow(escrowId)

  // Check it was deleted ok
  if (res.error || !res.data) {
    throw new Error(res.message)
  }

  return {
    error: false,
    message: t('escrow_deleted_successfully'),
    completion: 'COMPLETE',
    data: true,
  }
}
