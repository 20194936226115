import { useEffect, useState } from 'react'

import Modal from '@/components/modal'
import { FormErrorsManager } from '@/contexts/formErrors'

import { ModalProps } from '../../modal.types'

interface Props {
  content: ModalProps | null
}
export const ModalContent = (props: Props) => {
  const [_selectedModal, setSelectedModal] = useState<ModalProps | null>(
    props.content
  )
  const [_visible, setVisible] = useState<boolean>(false)

  useEffect(() => {
    // Check if anything has changed
    if (
      (_selectedModal === null && props.content !== null) ||
      (_selectedModal !== null && props.content === null) ||
      _selectedModal?.id !== props.content?.id
    ) {
      // Somethings changed - start by clearing down form errors
      // to keep the situation clear
      FormErrorsManager.clearErrors()

      // Check if we're currently displaying something
      if (_selectedModal !== null) {
        // We are displaying something so lets close that
        setVisible(false)
      }

      // Set the newly selected modal
      setSelectedModal(props.content)
    }
  }, [props.content])

  useEffect(() => {
    // This will be called after a selected modal has been changed
    // meaning the modal will be rendered but not visible yet
    // Lets check that the selected modal isn't null and if not
    // we'll show the modal now
    if (_selectedModal) {
      setVisible(true)
    }
  }, [_selectedModal])

  const onClose = () => {
    // Hide the modal first
    setVisible(false)

    // Wait a delay and then clear the modal from our state
    setTimeout(() => {
      setSelectedModal(null)
    }, 300)
  }

  // If there is no selected modal then we can
  // return null
  if (!_selectedModal) {
    return null
  }

  // Otherwise we'll figure out which to display
  // depending on the id of the props
  switch (_selectedModal.id) {
    case 'TRANSFER_ASSET':
      return (
        <Modal.TransferAsset
          {..._selectedModal}
          visible={_visible}
          onClose={onClose}
        />
      )
    case 'TRANSFER_ASSET_REQUESTED':
      return (
        <Modal.TransferAssetRequested
          {..._selectedModal}
          visible={_visible}
          onClose={onClose}
        />
      )
    case 'WORKSPACE_INVITE':
      return (
        <Modal.WorkspaceInvite
          {..._selectedModal}
          visible={_visible}
          onClose={onClose}
        />
      )
    case 'WORKSPACE_INVITE_SENT':
      return (
        <Modal.WorkspaceInviteSent
          {..._selectedModal}
          visible={_visible}
          onClose={onClose}
        />
      )
    case 'DELETE_USER':
      return (
        <Modal.DeleteUser
          {..._selectedModal}
          visible={_visible}
          onClose={onClose}
        />
      )
    case 'TWO_FACTOR_SETUP':
      return (
        <Modal.TwoFactorSetup
          {..._selectedModal}
          visible={_visible}
          onClose={onClose}
        />
      )
    case 'FORGOTTEN_PASSWORD':
      return (
        <Modal.ForgottenPassword
          {..._selectedModal}
          visible={_visible}
          onClose={onClose}
        />
      )
    case 'SHARE_ASSET':
      return (
        <Modal.ShareAsset
          {..._selectedModal}
          visible={_visible}
          onClose={onClose}
        />
      )
    case 'SHARE_ASSET_REQUESTED':
      return (
        <Modal.ShareAssetRequested
          {..._selectedModal}
          visible={_visible}
          onClose={onClose}
        />
      )
    case 'QR_WALLET_ADDRESS':
      return (
        <Modal.QrWalletAddress
          {..._selectedModal}
          visible={_visible}
          onClose={onClose}
        />
      )
    case 'ADD_BLOCKCHAIN_ADDRESS':
      return (
        <Modal.AddBlockchainAddress
          {..._selectedModal}
          visible={_visible}
          onClose={onClose}
        />
      )
    case 'ESCROW_APPROVERS':
      return (
        <Modal.EscrowApprovers
          {..._selectedModal}
          visible={_visible}
          onClose={onClose}
        />
      )
    case 'ESCROW_FEE':
      return (
        <Modal.EscrowFee
          {..._selectedModal}
          visible={_visible}
          onClose={onClose}
        />
      )
  }
}
