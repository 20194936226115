import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Avatar } from '@/components/avatar'
import { Button } from '@/components/button'
import { IEscrowUser, IOperationWithConfig } from '@/types/escrows'

import Cell from '../components/cell.table'
import Row from '../components/row.table'
import { BaseTableProps } from '../types'
import { BaseTable } from './base.table'

type SortBy = 'az' | 'party'

export interface Props extends BaseTableProps {
  approvers: IEscrowUser[]
  escrow: IOperationWithConfig
  onAddNew?: () => void
  onRowClick?: (user: IEscrowUser) => void
}

export const ApproversTable: React.FC<Props> = (props: Props) => {
  const defaultSortBy: SortBy = 'az'
  const { t } = useTranslation()
  const [_approvers, setApprovers] = useState<IEscrowUser[]>([])
  const [_sortBy, setSortBy] = useState<SortBy>(defaultSortBy)
  const _sortByRef = useRef<SortBy>(_sortBy)

  // Monitor for incoming changes to the raw cases data
  useEffect(() => {
    setApprovers([...sort(props.approvers)])
  }, [props.approvers])

  // Monitor for sortBy changes + update
  useEffect(() => {
    if (_sortBy !== _sortByRef.current) {
      _sortByRef.current = _sortBy
      setApprovers([...sort(_approvers)])
    }
  }, [_sortBy])

  const onSortChanged = (id: SortBy) => setSortBy(id)

  const sort = (approvers: IEscrowUser[]): IEscrowUser[] => {
    switch (_sortBy) {
      case 'az':
        return approvers.sort((a, b) => a.name.localeCompare(b.name))
      case 'party':
        return approvers.sort((a, b) =>
          (a.party ?? '').localeCompare(b.party ?? '')
        )
      default:
        return approvers
    }
  }

  const renderRow = (row: IEscrowUser, index: number) => {
    return (
      <Row
        key={`${row.name}_${index}`}
        onClick={props.onRowClick ? () => props.onRowClick?.(row) : undefined}
      >
        <Cell.Icon>
          <Avatar type={'monogram'} value={row.name} />
        </Cell.Icon>
        <Cell.Text>{row.name}</Cell.Text>
        <Cell.Text align={'center'}>
          <b>{row.party}</b>
        </Cell.Text>
        <Cell.Text>{row.email}</Cell.Text>
        <Cell.Text>{row.phone}</Cell.Text>
        <Cell.Button>
          {row.two_factor_enabled && (
            <Button
              layout={'icon'}
              variant={'shape'}
              shape={'square'}
              size={'small'}
              hierarchy={'tertiary'}
              icon={{
                name: 'lock',
              }}
              onClick={() => {
                console.log('2FA')
              }}
            />
          )}
        </Cell.Button>
      </Row>
    )
  }

  return (
    <>
      <BaseTable<IEscrowUser, SortBy, null, null>
        testId={props.testId}
        showLeftController={props.showLeftController}
        showFilters={props.showFilters}
        showSort={props.showSort}
        leftController={
          props.onAddNew
            ? {
                buttons: [
                  {
                    icon: {
                      name: 'plus',
                    },
                    label: t('add_approver'),
                    onClick: props.onAddNew,
                  },
                ],
              }
            : undefined
        }
        sort={{
          options: [
            {
              id: 'az',
              value: 'az',
              label: t('a-z'),
              icon: 'user-circle',
            },
            {
              id: 'party',
              value: 'party',
              label: t('party'),
              icon: 'people-group',
              iconVariant: 'regular',
            },
          ],
          onSortByChanged: onSortChanged,
          defaultSelectedId: defaultSortBy,
        }}
        headers={[
          {
            label: t('username'),
            colSpan: 2,
          },
          {
            label: t('party'),
            alignment: 'center',
          },
          {
            label: t('email'),
          },
          {
            label: t('mobile'),
          },
          {
            label: t('2fa'),
            alignment: 'center',
          },
        ]}
        data={_approvers}
        onRenderRow={renderRow}
      />
    </>
  )
}
