import { t } from 'i18next'
import { useNavigate } from 'react-router-dom'

import { DropdownView } from '@/components/dropdown/components/view.dropdown'
import { Icon } from '@/components/icon'
import { ListItem } from '@/components/list-item'
import { ROUTES } from '@/constants/routes'
import { IAsset } from '@/types/asset'

import { Props as ViewProps } from '../../components/view.dropdown'

interface Props extends ViewProps<unknown> {
  asset: IAsset
}
const EscrowAssetActionsView = (props: Props) => {
  const navigate = useNavigate()

  return (
    <DropdownView
      {...props}
      id={'ESCROW_ASSET_ACTIONS'}
      items={[
        {
          title: t('view_asset'),
          icon: 'arrow-up-right-from-square',
          onClick: () => {
            navigate(
              ROUTES.ASSETS.DETAIL.replace(
                ':asset_id',
                props.asset?.id
              ).replace(':asset_id', props.asset?.id),
              {
                state: {
                  readOnly: true,
                },
              }
            )
          },
        },
      ].map((v, i) => (
        <ListItem
          key={`item_${i}`}
          title={v.title}
          leading={
            <Icon
              size={'small'}
              family={'sharp'}
              name={v.icon}
              variant={'solid'}
            />
          }
          className="pl-2 pr-2"
          onClick={v.onClick}
        />
      ))}
    />
  )
}
export default EscrowAssetActionsView
