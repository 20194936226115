import { t } from 'i18next'

import { updateEscrowConfig } from '@/api/escrows/v1'
import { REGEX } from '@/constants/regex'
import { queryClient } from '@/contexts'
import { FormErrorsManager } from '@/contexts/formErrors'
import { IsNot } from '@/helpers/test'
import { key as QueryAllEscrowsKey } from '@/hooks/queries/useAllEscrows'
import { key as QueryEscrowsKey } from '@/hooks/queries/useEscrows'
import { ActionResponse } from '@/types/actions'
import { IEscrowConfig } from '@/types/escrows'

export const DeleteEscrowUser = async (
  formData: FormData
): Promise<ActionResponse<boolean>> => {
  const type = formData.get('type')?.toString()
  const id = formData.get('id')?.toString()
  const config = formData.get('config')?.toString()
  const party = formData.get('party_group')?.toString()

  // Check basic details
  if (type !== 'INITIATOR' && type !== 'APPROVER') {
    return FormErrorsManager.addErrors([
      {
        fieldName: '',
        error: t('invalid_user_type_provided'),
      },
    ])
  } else if (IsNot(config, REGEX.IS_ANY)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: '',
        error: t('invalid_config_provided'),
      },
    ])
  } else if (IsNot(id, REGEX.IS_ANY)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: '',
        error: t('invalid_id_provided'),
      },
    ])
  }

  // Attempt to parse the original config
  const newConfig: IEscrowConfig = {
    ...(JSON.parse(config) || {}),
  }

  // Remove the user from the config
  if (party === 'A') {
    newConfig.group_a.users =
      newConfig.group_a.users?.filter((u) => u.id !== id) ?? []
  } else {
    newConfig.group_b.users =
      newConfig.group_b.users?.filter((u) => u.id !== id) ?? []
  }

  // Update the escrow config
  const res = await updateEscrowConfig(newConfig)

  // Check for errors
  if (res.error) {
    throw new Error(res.message ?? t('something_went_wrong'))
  }

  // Invalidate cache
  await Promise.all([
    queryClient.invalidateQueries({
      queryKey: [QueryEscrowsKey],
    }),
    queryClient.invalidateQueries({
      queryKey: [QueryAllEscrowsKey],
    }),
  ])

  return {
    error: false,
    message: `${type === 'INITIATOR' ? t('initiator') : t('approver')} ${t('removed_successfully')}`,
    completion: 'COMPLETE',
    data: true,
  }
}
