import { getClient, getConfig } from '@/api/common'
import { BaseResponse } from '@/types/api'
import {
  ICustodian,
  ICustodianSupportedAsset,
  ISupportedAsset,
} from '@/types/custodian'
import { IOrganisation } from '@/types/organisation'

interface UpdateCustodianProps {
  custodian_id: string
  organization_id: string
}

export const getOrganisation = async (): Promise<
  BaseResponse<IOrganisation>
> => {
  const client = await getClient((await getConfig()).AUTH_API_URL)
  return client.get<BaseResponse<IOrganisation>>(`/org/me`).then((response) => {
    return response.data
  })
}

export const getAllCustodians = async (): Promise<
  BaseResponse<ICustodian[]>
> => {
  const client = await getClient((await getConfig()).BROKER_API_URL)
  return client
    .get<BaseResponse<ICustodian[]>>(`/custodian/all`)
    .then((response) => {
      return response.data
    })
}

export const getCustodians = async (
  orgId: string
): Promise<BaseResponse<ICustodian[]>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL)
  return client
    .get<BaseResponse<ICustodian[]>>(`/org/${orgId}/custodians`)
    .then((response) => {
      return response.data
    })
}

export const updateCustodians = async (
  data: UpdateCustodianProps
): Promise<BaseResponse<UpdateCustodianProps>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL)
  return client
    .post<BaseResponse<UpdateCustodianProps>>(`/custodian/config/org`, data)
    .then((response) => {
      return response.data
    })
}

interface IAddCustodianProps {
  name: string
}
export const addCustodian = async (
  data: IAddCustodianProps
): Promise<BaseResponse<ICustodianSupportedAsset>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL)
  return client
    .post<BaseResponse<ICustodianSupportedAsset>>(`/custodian/add`, data)
    .then((response) => {
      return response.data
    })
}

export const addSupportedAsset = async (
  data: ICustodianSupportedAsset
): Promise<BaseResponse<ICustodianSupportedAsset>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL)
  return client
    .post<BaseResponse<ICustodianSupportedAsset>>(`/custodian/asset/add`, data)
    .then((response) => {
      return response.data
    })
}

export const getSupportedAssets = async (): Promise<
  BaseResponse<ISupportedAsset[]>
> => {
  const client = await getClient((await getConfig()).BROKER_API_URL_V2)
  return client
    .get<BaseResponse<ISupportedAsset[]>>(`/custodian/supported-assets`)
    .then((response) => {
      return response.data
    })
}
