import { t } from 'i18next'
import { useNavigate } from 'react-router-dom'

import { DropdownView } from '@/components/dropdown/components/view.dropdown'
import { Icon } from '@/components/icon'
import { ListItem } from '@/components/list-item'
import { ROUTES } from '@/constants/routes'
import { useDrawer } from '@/contexts/interface'
import { IOperationWithConfig } from '@/types/escrows'

import { Props as ViewProps } from '../../components/view.dropdown'

interface Props extends ViewProps<unknown> {
  escrow: IOperationWithConfig
}
const AdminEscrowActionsView = (props: Props) => {
  const navigate = useNavigate()
  const { setDrawer } = useDrawer()

  const handleNavigateToDetails = () => {
    navigate(
      ROUTES.ADMIN.ESCROW.DETAIL.replace(
        ':escrow_id',
        props.escrow.operation.id
      ).replace(':escrow_id', props.escrow.operation.id),
      {
        state: {
          row: props.escrow,
        },
      }
    )
  }

  const editEscrow = () => {
    setDrawer({
      id: 'EDIT_ESCROW',
      escrow: props.escrow,
    })
  }

  return (
    <DropdownView
      {...props}
      id={'ADMIN_ESCROW_ACTIONS'}
      items={[
        {
          title: t('view_details'),
          icon: 'eye',
          onClick: handleNavigateToDetails,
        },
        {
          title: t('edit_escrow'),
          icon: 'edit',
          onClick: editEscrow,
        },
      ].map((v, i) => (
        <ListItem
          key={`item_${i}`}
          title={v.title}
          leading={
            <Icon
              size={'small'}
              family={'sharp'}
              name={v.icon}
              variant={'solid'}
            />
          }
          className="pl-2 pr-2"
          onClick={v.onClick}
        />
      ))}
    />
  )
}
export default AdminEscrowActionsView
