import { t } from 'i18next'

import { ActionResponse } from '@/types/actions'

export const EscrowMarkReady = async (): Promise<ActionResponse<boolean>> => {
  return {
    error: false,
    message: t('escrow_documents_submitted_successfully'),
    completion: 'COMPLETE',
    data: true,
  }
}
