import { t } from 'i18next'

import { DropdownView } from '@/components/dropdown/components/view.dropdown'
import { Icon } from '@/components/icon'
import { ListItem } from '@/components/list-item'
import { IEscrowUser } from '@/types/escrows'

import { Props as ViewProps } from '../../components/view.dropdown'

interface Props extends ViewProps<unknown> {
  user: IEscrowUser
  onRemoveUser: (user: IEscrowUser) => void
}
const ApproverActionsView = (props: Props) => {
  const onRemoveUser = () => {
    props.onRemoveUser(props.user)
  }

  return (
    <DropdownView
      {...props}
      id={'APPROVER_ACTIONS'}
      items={[
        {
          title: t('remove_approver'),
          icon: 'trash',
          onClick: onRemoveUser,
        },
      ].map((v, i) => (
        <ListItem
          key={`item_${i}`}
          title={v.title}
          leading={
            <Icon
              size={'small'}
              family={'sharp'}
              name={v.icon}
              variant={'solid'}
            />
          }
          className="pl-2 pr-2"
          onClick={v.onClick}
        />
      ))}
    />
  )
}
export default ApproverActionsView
