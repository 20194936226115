import DropdownController, {
  Props as ControllerProps,
} from '@/components/dropdown/components/controller.dropdown'
import { IEscrowUser } from '@/types/escrows'

import InitiatorsActionsView from './views/initiators-actions.view'

interface Props extends ControllerProps<unknown> {
  user: IEscrowUser
  onRemoveUser: (user: IEscrowUser) => void
}
const InitiatorActionsController = (props: Props) => {
  return (
    <DropdownController
      {...props}
      defaultViewId={'INITIATOR_ACTIONS'}
      closeOnItemClick={true}
    >
      <InitiatorsActionsView
        user={props.user}
        onRemoveUser={props.onRemoveUser}
      />
    </DropdownController>
  )
}
export default InitiatorActionsController
