import { t } from 'i18next'
import { useState } from 'react'

import { INTENTS } from '@/actions/intents'
import { EscrowTokenSelector } from '@/components/asset/escrow-token-selector'
import Button from '@/components/button'
import { Form } from '@/components/form'
import { Group } from '@/components/group'
import { Icon } from '@/components/icon'
import Input from '@/components/input'
import { ListItem } from '@/components/list-item'
import { BannerManager } from '@/contexts/banner'
import { ToastManager } from '@/contexts/toast'
import { formatNumber } from '@/helpers/formatValue'
import { useOrgs } from '@/hooks/queries/useOrgs'
import { ActionResponse } from '@/types/actions'
import { IOperationWithConfig } from '@/types/escrows'

import { Drawer } from '../..'
import { BaseProps } from '../types'

export interface Props extends BaseProps {
  escrow: IOperationWithConfig
  onUpdated?: (operationId: string) => void
}
export const EditEscrowDrawer = (props: Props) => {
  const { orgs } = useOrgs()
  const [_submitting, setSubmitting] = useState<INTENTS | null>(null)

  // Clear the submitting flag
  const clearSubmitting = () => setSubmitting(null)

  // On form submission
  const onSubmit = (intent: INTENTS) => setSubmitting(intent)

  // On form exception
  const onException = (actionRes: ActionResponse<string>) => {
    BannerManager.showBanner({
      variant: 'page',
      type: 'error',
      title: actionRes.message ?? t('something_went_wrong'),
    })
    props.onClose()
  }

  // On form submit successfully
  const onSuccess = (actionRes: ActionResponse<string>) => {
    props.onUpdated?.(actionRes.data ?? '')
    ToastManager.showToast({
      type: 'success',
      text: actionRes.message ?? t('action_successful'),
    })
    props.onClose()
  }

  return (
    <Drawer
      title={t('edit_escrow')}
      description={t('new_escrow_drawer_description')}
      visible={props.visible}
      onClose={props.onClose}
    >
      <Form<string, INTENTS>
        type={'other'}
        testId={'edit-escrow-form'}
        buttons={[
          <Button.Basic
            key={'btn_edit_escrow'}
            label={t('apply_changes')}
            state={
              _submitting === INTENTS.EDIT_ESCROW
                ? 'loading'
                : _submitting !== null
                  ? 'disabled'
                  : 'default'
            }
            withAttributes={{
              type: 'submit',
              name: 'intent',
              value: INTENTS.EDIT_ESCROW,
            }}
          />,
        ]}
        onSubmit={onSubmit}
        onSuccess={onSuccess}
        onException={onException}
        onFailed={clearSubmitting}
        onUnknownResponse={clearSubmitting}
        containerClassName={'gap-[48px]'}
      >
        <input
          type={'hidden'}
          name={'orginal_escrow'}
          value={JSON.stringify(props.escrow)}
        />

        <Group label={t('details')}>
          <Input.Text
            name={'escrow_organization'}
            label={t('organisation')}
            state={'readonly'}
            value={
              orgs.find((o) => o.id === props.escrow.operation.organization_id)
                ?.name ?? '-'
            }
            icon={
              <Icon name={'buildings'} family={'sharp'} variant={'solid'} />
            }
          />

          <Input.Text
            id="escrow_name"
            name="escrow_name"
            label={t('escrow_name')}
            hint={t('create_escrow_name_bottom_text')}
            placeholder={t('create_escrow_name_placeholder')}
            defaultValue={props.escrow.operation.name}
            maxLength={35}
          />
        </Group>

        <Group label={t('parties')}>
          <div className={'flex flex-row gap-[24px]'}>
            <Input.Text
              id="party_a"
              name="party_a"
              label={t('create_escrow_party_a_label')}
              hint={t('create_escrow_party_a_bottom_text')}
              placeholder={t('create_escrow_party_a_placeholder')}
              defaultValue={props.escrow.config.group_a.name}
            />
            <Input.Dropdown
              name="party_a_min_approvals"
              label={t('approvals')}
              defaultValue={(
                props.escrow.config.group_a.min_approvals ?? 1
              ).toString()}
              items={Array.from({ length: 10 }).map((_a, i) => (
                <ListItem
                  key={i + 1}
                  value={(i + 1).toString()}
                  title={formatNumber(i + 1, { minimumIntegerDigits: 2 })}
                  className={'pl-2 pr-2'}
                />
              ))}
              className={'max-w-[6rem] tablet:max-w-[7rem]'}
            />
          </div>

          <div className={'flex flex-row gap-[24px]'}>
            <Input.Text
              id="party_b"
              name="party_b"
              label={t('create_escrow_party_b_label')}
              hint={t('create_escrow_party_b_bottom_text')}
              placeholder={t('create_escrow_party_b_placeholder')}
              defaultValue={props.escrow.config.group_b.name}
            />
            <Input.Dropdown
              name="party_b_min_approvals"
              label={t('approvals')}
              defaultValue={(
                props.escrow.config.group_b.min_approvals ?? 1
              ).toString()}
              items={Array.from({ length: 10 }).map((_a, i) => (
                <ListItem
                  key={i + 1}
                  value={(i + 1).toString()}
                  title={formatNumber(i + 1, { minimumIntegerDigits: 2 })}
                  className={'pl-2 pr-2'}
                />
              ))}
              className={'max-w-[6rem] tablet:max-w-[7rem]'}
            />
          </div>
        </Group>

        <EscrowTokenSelector
          name={'escrow_assets'}
          organisationId={props.escrow.operation.organization_id}
          escrow={props.escrow}
        />
      </Form>
    </Drawer>
  )
}
