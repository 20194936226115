import { t } from 'i18next'

import { updateEscrowConfig } from '@/api/escrows/v1'
import { REGEX } from '@/constants/regex'
import { queryClient } from '@/contexts'
import { FormErrorsManager } from '@/contexts/formErrors'
import { IsNot } from '@/helpers/test'
import { key as QueryAllEscrowsKey } from '@/hooks/queries/useAllEscrows'
import { key as QueryEscrowsKey } from '@/hooks/queries/useEscrows'
import { ActionResponse } from '@/types/actions'
import { IEscrowConfig, IEscrowUser } from '@/types/escrows'

export const CreateEscrowUser = async (
  formData: FormData
): Promise<ActionResponse<IEscrowUser>> => {
  const type = formData.get('type')?.toString()
  const name = formData.get('name')?.toString()
  const email = formData.get('email')?.toString()
  const phone = formData.get('phone')?.toString()
  const config = formData.get('config')?.toString()
  const party = formData.get('party_group')?.toString()

  // Check basic details
  if (type !== 'INITIATOR' && type !== 'APPROVER') {
    return FormErrorsManager.addErrors([
      {
        fieldName: '',
        error: t('invalid_user_type_provided'),
      },
    ])
  } else if (IsNot(config, REGEX.IS_ANY)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: '',
        error: t('invalid_config_provided'),
      },
    ])
  } else if (IsNot(name, REGEX.IS_HUMAN_NAME)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'name',
        error: t('invalid_name_provided'),
      },
    ])
  } else if (IsNot(email, REGEX.IS_EMAIL)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'email',
        error: t('invalid_email_provided'),
      },
    ])
  } else if (phone && phone.length > 0 && IsNot(phone, REGEX.IS_PHONE_NUMBER)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'phone',
        error: t('invalid_phone_number'),
      },
    ])
  }

  // Attempt to parse the original config
  const newConfig: IEscrowConfig = {
    ...(JSON.parse(config) || {}),
  }

  // Create the new user object
  const newUser: IEscrowUser = {
    name: name,
    email: email.toLowerCase(),
    phone: phone,
    is_approver: type === 'APPROVER',
    is_initiator: type === 'INITIATOR',
    is_mandatory: false,
  }

  // Make sure the groups are initiated
  newConfig.group_a.users ??= []
  newConfig.group_b.users ??= []

  // Before we create the user lets check to see if a user with this email already exists
  // against this escrow
  const foundInGroupA = newConfig.group_a.users.find(
    (u) => u.email.toLowerCase() === newUser.email
  )
  const foundInGroupB = newConfig.group_b.users.find(
    (u) => u.email.toLowerCase() === newUser.email
  )
  if (foundInGroupA) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'email',
        error: `User with this email already exists as an ${foundInGroupA.is_approver ? 'Approver' : 'Initiator'} for Party A`,
      },
    ])
  } else if (foundInGroupB) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'email',
        error: `User with this email already exists as an ${foundInGroupB.is_approver ? 'Approver' : 'Initiator'} for Party B`,
      },
    ])
  }

  // Add the user into the config under the correct group
  if (party === 'A') {
    newConfig.group_a.users.push(newUser)
  } else {
    newConfig.group_b.users.push(newUser)
  }

  // Create a user object
  const res = await updateEscrowConfig(newConfig)

  // Check for errors
  if (res.error) {
    throw new Error(res.message ?? t('something_went_wrong'))
  }

  // Invalidate cache
  await Promise.all([
    queryClient.invalidateQueries({
      queryKey: [QueryEscrowsKey],
    }),
    queryClient.invalidateQueries({
      queryKey: [QueryAllEscrowsKey],
    }),
  ])

  return {
    error: false,
    message: `${t('new')} ${type === 'INITIATOR' ? t('initiator') : t('approver')} ${t('added_successfully')}`,
    completion: 'COMPLETE',
    data: newUser,
  }
}
