import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import React from 'react'

import { AuthProvider } from './auth'
import { BannerProvider } from './banner'
import { ConfigProvider } from './config'
// import { DropdownProvider } from './dropdown'
import { FormErrorsProvider } from './formErrors'
import { InterfaceProvider } from './interface'
// import { ModalProvider } from './modal'
import { ThemeProvider } from './theme'
import { ToastProvider } from './toast'
import { WorkspaceProvider } from './workspace'

type ContextsProvidersProps = {
  type: 'GLOBAL_PROVIDERS' | 'NAVIGATION_PROVIDERS'
  children: JSX.Element | JSX.Element[]
}

type Provider = {
  component: React.FC<{
    children: JSX.Element | JSX.Element[]
  }>
  props?: Record<string, unknown>
}

export const queryClient = new QueryClient()

const ContextsProviders: React.FC<ContextsProvidersProps> = ({
  type,
  children,
}) => {
  /*
    Global providers are placed at the top level of the application - these cannot
    control page navigation
  */
  const globalProviders = [
    { component: QueryClientProvider, props: { client: queryClient } },
    { component: ThemeProvider },
    { component: ConfigProvider },
    { component: AuthProvider },
    { component: ToastProvider },
    { component: BannerProvider },
    { component: FormErrorsProvider },
    { component: WorkspaceProvider },
  ] as Provider[]

  /*
    Navigation providers are placed inside of the router allowing them access to
    page navigation controls
  */
  const navigationProviders = [{ component: InterfaceProvider }] as Provider[]

  const recursiveProviders = (
    providers: Provider[],
    content: JSX.Element | JSX.Element[]
  ) => {
    if (providers.length === 0) return content
    const [{ component: CurrentProvider, props = {} }, ...rest] = providers
    return (
      <CurrentProvider {...props}>
        {recursiveProviders(rest, content)}
      </CurrentProvider>
    )
  }

  return recursiveProviders(
    type === 'GLOBAL_PROVIDERS' ? globalProviders : navigationProviders,
    children
  )
}

export default ContextsProviders
