import DropdownController, {
  Props as ControllerProps,
} from '@/components/dropdown/components/controller.dropdown'
import { IAsset } from '@/types/asset'

import EscrowAssetActionsView from './views/escrow-asset-actions.view'

interface Props extends ControllerProps<unknown> {
  asset: IAsset
}
const EscrowAssetActionsController = (props: Props) => {
  return (
    <DropdownController
      {...props}
      defaultViewId={'ESCROW_ASSET_ACTIONS'}
      closeOnItemClick={true}
    >
      <EscrowAssetActionsView asset={props.asset} />
    </DropdownController>
  )
}
export default EscrowAssetActionsController
