import i18n from 'i18next'
import detector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import flag_al from '/flag/al.png'
import flag_de from '/flag/de.png'
import flag_es from '/flag/es.png'
import flag_fr from '/flag/fr.png'
import flag_mk from '/flag/mk.png'
import flag_nl from '/flag/nl.png'
import flag_sr from '/flag/sr.png'
import flag_uk from '/flag/uk.png'

import al from './locales/al.json'
import de from './locales/de.json'
import en from './locales/en.json'
import es from './locales/es.json'
import fr from './locales/fr.json'
import mk from './locales/mk.json'
import nl from './locales/nl.json'
import sr from './locales/sr.json'

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    resources: {
      en: { translations: en },
      es: { translations: es },
      fr: { translations: fr },
      de: { translations: de },
      nl: { translations: nl },
      al: { translations: al },
      mk: { translations: mk },
      sr: { translations: sr },
    },
    ns: ['translations'],
    defaultNS: 'translations',
  })

export type LanguageCode = 'en' | 'es' | 'fr' | 'de' | 'nl' | 'al' | 'mk' | 'sr'
export interface LanguageOption {
  flag: string
  value: LanguageCode
}
export const Languages: LanguageOption[] = [
  {
    flag: flag_uk,
    value: 'en',
  },
  {
    flag: flag_es,
    value: 'es',
  },
  {
    flag: flag_fr,
    value: 'fr',
  },
  {
    flag: flag_de,
    value: 'de',
  },
  {
    flag: flag_nl,
    value: 'nl',
  },
  {
    flag: flag_al,
    value: 'al',
  },
  {
    flag: flag_mk,
    value: 'mk',
  },
  {
    flag: flag_sr,
    value: 'sr',
  },
]

export default i18n
