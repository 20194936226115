import { t } from 'i18next'
import { useTranslation } from 'react-i18next'

import { SubmitAction } from '@/actions'
import { INTENTS } from '@/actions/intents'
import { DropdownView } from '@/components/dropdown/components/view.dropdown'
import { Icon } from '@/components/icon'
import { ListItem } from '@/components/list-item'
import { LanguageCode, LanguageOption, Languages } from '@/locale'

import { Props as ViewProps } from '../../components/view.dropdown'

interface Props extends ViewProps<LanguageOption> {}
const LanguageView = (props: Props) => {
  const { i18n } = useTranslation()

  const changeUsersLanguage = (code: LanguageCode) => {
    // Change the language locally
    i18n.changeLanguage(code)

    // Asyncronously update the users account to use this language
    SubmitAction(INTENTS.EDIT_USER_LANGUAGE, {
      language_code: code,
    })
  }

  return (
    <DropdownView
      {...props}
      id={'LANGUAGE'}
      items={Languages.map((lang, index) => (
        <ListItem
          key={`option_${index}`}
          title={t(lang.value)}
          data={lang}
          value={lang.value}
          style={'normal'}
          leading={<Icon flag={lang.flag} size={'medium'} />}
          {...(i18n.language === lang.value && {
            trailing: (
              <Icon
                name="circle-check"
                family={'sharp'}
                variant={'solid'}
                size={'medium'}
              />
            ),
          })}
          onClick={() => {
            changeUsersLanguage(lang.value)
          }}
          className={'pl-2 pr-2'}
        />
      ))}
    />
  )
}
export default LanguageView
