import { t } from 'i18next'

import { createEscrow, updateEscrowConfig } from '@/api/escrows/v1'
import { REGEX } from '@/constants/regex'
import { FormErrorsManager } from '@/contexts/formErrors'
import { IsNot } from '@/helpers/test'
import { ActionResponse } from '@/types/actions'
import { IDigitalAsset } from '@/types/asset'

export const CreateEscrow = async (
  formData: FormData
): Promise<ActionResponse<string>> => {
  const name = formData.get('escrow_name')?.toString()
  const partyAName = formData.get('party_a')?.toString()
  const partyBName = formData.get('party_b')?.toString()
  const partyAMinApprovals = formData.get('party_a_min_approvals')?.toString()
  const partyBMinApprovals = formData.get('party_b_min_approvals')?.toString()
  const assets = formData.get('escrow_assets')?.toString()

  // Validate the fields
  if (IsNot(assets, REGEX.IS_ANY)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: '',
        error: t('assets_not_found'),
      },
    ])
  } else if (IsNot(name, REGEX.IS_ESCROW_NAME)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'escrow_name',
        error: t('invalid_escrow_name_provided'),
      },
    ])
  } else if (IsNot(partyAName, REGEX.IS_ESCROW_GROUP_NAME)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'party_a',
        error: t('invalid_party_name_provided'),
      },
    ])
  } else if (IsNot(partyBName, REGEX.IS_ESCROW_GROUP_NAME)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'party_b',
        error: t('invalid party_b_name_provided'),
      },
    ])
  } else if (
    IsNot(partyAMinApprovals, REGEX.IS_NUMBER) ||
    parseInt(partyAMinApprovals) <= 0
  ) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'party_a',
        error: t('invalid_party_a_minimum_approvals_count'),
      },
    ])
  } else if (
    IsNot(partyBMinApprovals, REGEX.IS_NUMBER) ||
    parseInt(partyBMinApprovals) <= 0
  ) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'party_b',
        error: t('invalid_party_b_minimum_approvals_count'),
      },
    ])
  }

  // Attempt to parse the assets
  const parsedAssets = JSON.parse(assets) as IDigitalAsset[]

  // Remove any assets that are not complete or are duplicates
  const newAssets: IDigitalAsset[] = []
  for (const asset of parsedAssets) {
    if (asset.ticker && asset.network && asset.custodian?.provider) {
      const findAdded = newAssets.find(
        (a) => a.ticker === asset.ticker && a.network === asset.network
      )
      if (!findAdded) {
        newAssets.push(asset)
      }
    }
  }

  // Check we have some assets to add
  if (newAssets.length <= 0) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'escrow_assets',
        error: t('no_valid_assets_provided'),
      },
    ])
  }

  // Make the call to create the escrow
  const res = await createEscrow(name, partyAName, partyBName, newAssets)

  // Check the escrow was created OK
  if (res.error || !res.data) {
    throw new Error(res.message)
  }

  // Update the associated configuration file
  const configRes = await updateEscrowConfig({
    ...res.data.config,
    group_a: {
      ...res.data.config.group_a,
      name: partyAName,
      min_approvals: parseInt(partyAMinApprovals),
    },
    group_b: {
      ...res.data.config.group_b,
      name: partyBName,
      min_approvals: parseInt(partyBMinApprovals),
    },
  })

  // Check the config was updated OK
  if (configRes.error) {
    console.error('failed to update escrow config')
    return {
      error: false,
      message: t('escrow_saved_but_some_data_failed'),
      completion: 'COMPLETE_WITH_ERROR',
      data: res.data.operation.id,
    }
  }

  return {
    error: false,
    message: t('new_escrow_added_successfully'),
    completion: 'COMPLETE',
    data: res.data.operation.id,
  }
}
