import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'

import { NoData } from '@/components/no-data'
import { Paragraph } from '@/components/paragraph'
import Table from '@/components/table'
import { useEscrows } from '@/hooks/queries/useEscrows'

import EscrowSkeleton from './skeleton'

export const AdminEscrows: React.FC = (): JSX.Element => {
  const { escrows, isLoading: isLoadingEscrows } = useEscrows()
  const { t } = useTranslation()

  if (isLoadingEscrows) {
    return <EscrowSkeleton />
  }

  return (
    <div
      className={twMerge(
        'flex flex-col pt-4 min-h-[calc(100dvh-80px)]',
        'tablet:pt-0'
      )}
    >
      <div className={twMerge('flex flex-col p-8 pt-4', 'tablet:gap-8')}>
        <Paragraph
          title={t('escrow')}
          description={t('escrow_description')}
          spacerOverrides={{ description: [] }}
        />
      </div>

      <div className={'flex flex-1 flex-col px-8'}>
        {escrows?.length ? (
          <Table.AgGrid.Wrapper>
            <Table.AdminEscrows data={escrows} />
          </Table.AgGrid.Wrapper>
        ) : (
          <NoData description="no_escrows_description" />
        )}
      </div>
    </div>
  )
}
