import { useQuery } from '@tanstack/react-query'

import { getEscrowAssets } from '@/api/escrows/v1'

export const useEscrowAssets = (
  escrowId?: string,
  organisationId?: string,
  workspaceId?: string
) => {
  const { isLoading, data, refetch } = useQuery({
    queryKey: ['escrow-assets', escrowId, organisationId, workspaceId],
    queryFn: () =>
      getEscrowAssets(escrowId ?? '', organisationId ?? '', workspaceId ?? ''),
    enabled: !!escrowId && !!organisationId && !!workspaceId,
  })

  return { assets: data?.data ?? [], isLoading, refetchEscrowAssets: refetch }
}
