import DropdownController, {
  Props as ControllerProps,
} from '@/components/dropdown/components/controller.dropdown'
import { IEscrowUser } from '@/types/escrows'

import ApproverActionsView from './views/approver-actions.view'

interface Props extends ControllerProps<unknown> {
  user: IEscrowUser
  onRemoveUser: (user: IEscrowUser) => void
}
const ApproverActionsController = (props: Props) => {
  return (
    <DropdownController
      {...props}
      defaultViewId={'APPROVER_ACTIONS'}
      closeOnItemClick={true}
    >
      <ApproverActionsView
        user={props.user}
        onRemoveUser={props.onRemoveUser}
      />
    </DropdownController>
  )
}
export default ApproverActionsController
