import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { getDigitalAssets, getPhysicalAssets } from '@/api'
import Button from '@/components/button'
import Card from '@/components/card'
import { Checkbox, CheckedState } from '@/components/checkbox'
import Input from '@/components/input'
import { Paragraph } from '@/components/paragraph'
import Table from '@/components/table'
import AssetIcon from '@/components/table/rows/assetIcon.tsx'
import { Tabs } from '@/components/tabs'
import { getAssetTypeDescriptionForColumn } from '@/helpers/assetTypeDescription.ts'
import { formatAssetPrice } from '@/helpers/formatValue'
import { IAsset } from '@/types/asset'

interface Props {
  name: string
}

const hasOperation = (asset: IAsset): boolean => {
  return (asset.operation_id ?? '').replace(/0/g, '').trim().length > 0
}

type AssetCategory = 'all' | 'tangible' | 'digital'
export const AssetSelector = (props: Props) => {
  const [_loading, setLoading] = useState<boolean>(true)
  const [_selectedCategory, setSelectedCategory] =
    useState<AssetCategory>('all')
  const [_selectedAssets, setSelectedAssets] = useState<IAsset[]>([])
  const [_searchFilter, setSearchFilter] = useState<string>('')
  const [_filteredAssets, setFilteredAssets] = useState<IAsset[]>([])
  const [_assets, setAssets] = useState<IAsset[]>([])

  useEffect(() => {
    const asyncFunc = async () => {
      // Load our assets
      const assetRes = await Promise.all([
        getDigitalAssets('HAS_NO_OPERATIONS'),
        getPhysicalAssets('HAS_NO_OPERATIONS'),
      ])

      // First lets combine the 2 lists
      const combinedAssets = [
        ...(assetRes[0].data ?? []),
        ...(assetRes[1].data ?? []),
      ].filter((a) => !hasOperation(a))

      // Push the assets into our collection
      setAssets(combinedAssets)

      // Run the filter now
      filterAssets(combinedAssets)

      // Clear the loading flag
      setLoading(false)
    }
    asyncFunc()
  }, [])

  // Monitor certain values - when they change we need to filter
  // the visible assets
  useEffect(() => {
    filterAssets()
  }, [_searchFilter, _selectedCategory])

  // Figures out if a single asset should be displayed
  // based on the current filter states
  const filterAssets = (assets?: IAsset[]) => {
    setFilteredAssets([
      ...(assets || _assets).filter((asset) => {
        if (_selectedCategory === 'digital' && asset.category !== 'DIGITAL') {
          return false
        } else if (
          _selectedCategory === 'tangible' &&
          asset.category !== 'HIGH_VALUE_PERSONAL_PROPERTY' &&
          asset.category !== 'REAL_ESTATE' &&
          asset.category !== 'VEHICLE' &&
          asset.category !== 'FIAT_CASH'
        ) {
          return false
        } else {
          // The asset is OK for the current category so we now need to filter
          // based on the search string
          if (_searchFilter === '') {
            // No search filter so we're good to show :)
            return true
          } else {
            // Small function to receive an array of values
            // to search - returns true/false if the term is found
            const searchValues = (values: Array<string | undefined>) => {
              const term = _searchFilter.toLowerCase()
              return values
                .filter((v) => !!v)
                .join(' ')
                .toLowerCase()
                .includes(term)
            }

            // Depending on the category we'll search
            switch (asset.category) {
              case 'DIGITAL':
                return searchValues([
                  getAssetTypeDescriptionForColumn(asset),
                  asset.external_ref,
                  asset.platform_id,
                  asset.created_by,
                  asset.airtable_record_id,
                  asset.contract_address,
                  asset.network,
                  asset.ticker,
                ])
              case 'FIAT_CASH':
                return searchValues([
                  getAssetTypeDescriptionForColumn(asset),
                  asset.external_ref,
                  asset.platform_id,
                  asset.created_by,
                  asset.currency,
                ])
              case 'HIGH_VALUE_PERSONAL_PROPERTY':
                return searchValues([
                  getAssetTypeDescriptionForColumn(asset),
                  asset.external_ref,
                  asset.platform_id,
                  asset.created_by,
                  asset.type,
                  asset.serial_number,
                  asset.description,
                ])
              case 'REAL_ESTATE':
                return searchValues([
                  getAssetTypeDescriptionForColumn(asset),
                  asset.external_ref,
                  asset.platform_id,
                  asset.created_by,
                  asset.type,
                  asset.plot_number,
                  asset.owner_name,
                ])
              case 'VEHICLE':
                return searchValues([
                  getAssetTypeDescriptionForColumn(asset),
                  asset.external_ref,
                  asset.platform_id,
                  asset.created_by,
                  asset.make,
                  asset.model,
                ])
              default:
                return []
            }
          }
        }
      }),
    ])
  }

  const onSearchChange = (search: string) => setSearchFilter(search)

  const onCategoryChanged = (id: AssetCategory) => {
    setSelectedCategory(id)
  }

  const onAssetCheckChanged = async (id: string, state: CheckedState) => {
    const assetId = (a: IAsset) => `${a.id}_${a.category}`

    if (state === 'unchecked') {
      setSelectedAssets([..._selectedAssets.filter((a) => assetId(a) !== id)])
    } else if (state === 'checked') {
      const foundAsset = _assets.find((a) => assetId(a) === id)
      if (foundAsset) {
        setSelectedAssets([..._selectedAssets, foundAsset])
      }
    }
    return state
  }

  return (
    <div
      className={twMerge(
        'flex flex-1 flex-col p-4 items-start gap-[16px]',
        'p-[8px] pb-[0px] pb-[0px]',
        'rounded-md min-h-[60vh] max-h-[60vh] justify-center',
        _selectedAssets.length > 0 && 'rounded-b-none'
      )}
    >
      {_loading ? (
        <Card.PageState
          type={'loading'}
          subTitle={t('loading_assets')}
          description={t('please_wait')}
        />
      ) : (
        <>
          <Input.Search
            size={'medium'}
            placeholder={t('search_for_assets')}
            autoComplete={'off'}
            onChange={onSearchChange}
            className={'max-w-none w-full flex self-stretch'}
          />

          <Tabs<AssetCategory>
            size={'medium'}
            type={'text'}
            values={[
              {
                id: 'all',
                text: t('all'),
              },
              {
                id: 'tangible',
                text: t('tangible'),
              },
              {
                id: 'digital',
                text: t('digital'),
              },
            ]}
            selectedId={_selectedCategory}
            onSelectionChanged={onCategoryChanged}
            className={'w-full self-stretch'}
          />

          <div
            className={twMerge(
              'flex flex-1 flex-col',
              'self-stretch overflow-auto pr-4'
            )}
            data-testid="asset-selector"
          >
            {_filteredAssets.length <= 0 ? (
              <div className={'flex h-full items-center justify-center'}>
                <Card.PageState
                  type={'information'}
                  subTitle={t('no_data')}
                  description={t('no_data_description')}
                />
              </div>
            ) : (
              <>
                <table
                  className={'w-full'}
                  data-testid={'table_asset_selector'}
                >
                  <tbody>
                    {_filteredAssets
                      .sort((a, b) =>
                        (a.external_ref ?? '').localeCompare(
                          b.external_ref ?? ''
                        )
                      )
                      .map((a, i) => (
                        <Table.Components.Row key={`key_${i}`}>
                          <Table.Components.Cell.Image>
                            <AssetIcon asset={a} />
                          </Table.Components.Cell.Image>
                          <Table.Components.Cell.Text
                            className={'max-w-[9.75rem]'}
                          >
                            {getAssetTypeDescriptionForColumn(a)}
                          </Table.Components.Cell.Text>
                          <Table.Components.Cell.Text
                            className={'max-w-[12rem]'}
                          >
                            {a.external_ref ?? ''}
                          </Table.Components.Cell.Text>
                          <Table.Components.Cell.Text
                            className={'max-w-[12rem]'}
                          >
                            {a.platform_id ?? '-'}
                          </Table.Components.Cell.Text>
                          <Table.Components.Cell.Number align={'right'}>
                            {formatAssetPrice(a.price)}
                          </Table.Components.Cell.Number>
                          <Table.Components.Cell.Checkbox>
                            <Checkbox
                              key={`checkbox_${a.id}_${a.category}`}
                              id={`${a.id}_${a.category}`}
                              checked={
                                _selectedAssets.find(
                                  (_a) =>
                                    `${_a.id}_${_a.category}` ===
                                    `${a.id}_${a.category}`
                                )
                                  ? 'checked'
                                  : 'unchecked'
                              }
                              onCheckChanged={onAssetCheckChanged}
                            />
                          </Table.Components.Cell.Checkbox>
                        </Table.Components.Row>
                      ))}
                  </tbody>
                </table>
                {_selectedAssets.map((a, i) => (
                  <input
                    key={`selected_asset_${i}`}
                    name={props.name}
                    type={'hidden'}
                    value={JSON.stringify(a)}
                  />
                ))}
              </>
            )}
          </div>
        </>
      )}
      {_selectedAssets.length > 0 && (
        <div
          className={twMerge(
            'flex flex-row w-full rounded-md rounded-t-none',
            'p-4 py-2 items-center',
            'bg-[#212427]'
          )}
        >
          <div className={'flex flex-1'}>
            <Paragraph
              description={`${_selectedAssets.length} ${t('items_selected')}`}
              classOverrides={{
                description: 'text-[#F5F5F6]',
              }}
              spacerOverrides={{
                description: [],
              }}
            />
          </div>
          <Button.Basic
            hierarchy={'tertiary-inverted'}
            label={t('unselect_all')}
            size={'small'}
            onClick={() => setSelectedAssets([])}
            className={'self-auto'}
            withAttributes={{
              type: 'button',
            }}
          />
        </div>
      )}
    </div>
  )
}
