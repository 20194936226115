import { t } from 'i18next'

import { setEscrowWithdrawalReady } from '@/api/escrows/v1'
import { REGEX } from '@/constants/regex'
import { queryClient } from '@/contexts'
import { FormErrorsManager } from '@/contexts/formErrors'
import { IsNot } from '@/helpers/test'
import { key as AllEscrowKey } from '@/hooks/queries/useAllEscrows'
import { key as QueryEscrowKey } from '@/hooks/queries/useEscrow'
import { key as EscrowKey } from '@/hooks/queries/useEscrows'
import { key as EscrowWithdrawalKey } from '@/hooks/queries/useEscrowWithdrawals'
import { ActionResponse } from '@/types/actions'
import { IOperationWithConfig } from '@/types/escrows'

export const EscrowSubmitForApproval = async (
  formData: FormData
): Promise<ActionResponse<boolean>> => {
  const escrow = formData.get('escrow')?.toString()

  // Validate the fields
  if (IsNot(escrow, REGEX.IS_ANY)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: '',
        error: t('escrow_object_not_found'),
      },
    ])
  }

  // Attempt to parse the original escrow object
  const parsedEscrow = JSON.parse(escrow) as IOperationWithConfig

  // Make the call to set the escrow as withdrawal ready - this is the
  // same as the admin marking the documents as approved
  const res = await setEscrowWithdrawalReady(
    parsedEscrow.operation.name,
    parsedEscrow.operation.id
  )

  // Check it actioned ok
  if (res.error) {
    throw new Error(res.message)
  }

  // Invalidate the cache
  await Promise.all([
    queryClient.invalidateQueries({
      queryKey: [QueryEscrowKey, parsedEscrow.operation.id],
    }),
    queryClient.invalidateQueries({ queryKey: [EscrowWithdrawalKey] }),
    queryClient.invalidateQueries({
      queryKey: [EscrowKey],
    }),
    queryClient.invalidateQueries({
      queryKey: [AllEscrowKey],
    }),
  ])

  return {
    error: false,
    message: t('escrow_documents_approved'),
    completion: 'COMPLETE',
    data: true,
  }
}
