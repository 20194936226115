import { useQuery } from '@tanstack/react-query'

import { getAllEscrows } from '@/api/escrows/v1'

export const key = 'escrows-all'
export const useAllEscrows = () => {
  const { isLoading, data } = useQuery({
    queryKey: [key],
    queryFn: () => getAllEscrows(),
  })

  return { escrows: data?.data ?? [], isLoading }
}
