import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import Button from '@/components/button'
import Input from '@/components/input'
import { ToastManager } from '@/contexts/toast'
import { capitalizeFirstLetter } from '@/helpers/capitalizeFirstLetter'
import { useCustodians } from '@/hooks/queries/useCustodians'
import { useEscrowAssets } from '@/hooks/queries/useEscrowAssets'
import {
  AssetCategory,
  AssetWalletType,
  DigitalAssetType,
  DigitalWalletSource,
  IDigitalAsset,
} from '@/types/asset'
import { ICustodian } from '@/types/custodian'
import { IOperationWithConfig } from '@/types/escrows'

import { RowTypes } from '../dropdown/components/list.dropdown'
import { Icon } from '../icon'
import { ListItem } from '../list-item'

interface Props {
  name: string
  organisationId: string | null
  escrow?: IOperationWithConfig
}

type TokenCustodians = {
  [ticker: string]: {
    [network: string]: ICustodian[]
  }
}

type TickerNetwork = {
  ticker: string
  network: string
}

export const EscrowTokenSelector = (props: Props) => {
  const { custodians } = useCustodians(
    props.escrow?.operation.organization_id ?? props.organisationId ?? ''
  )
  const { assets, isLoading: isLoadingAssets } = useEscrowAssets(
    props.escrow?.operation.id,
    props.escrow?.operation.organization_id,
    props.escrow?.operation.workspace_id
  )
  const [_allTokens, setAllTokens] = useState<TokenCustodians>({})
  const [_newAssets, setNewAssets] = useState<IDigitalAsset[]>([])

  useEffect(() => {
    if (Array.isArray(custodians) && custodians.length > 0) {
      const tokens: TokenCustodians = {}

      // Run through an build a list of available tokens in an order
      // we can use. This needs to be changed as in the UI we decide
      // the token first followed by the custodian where as the data
      // is in the reverse order.
      for (const custodian of custodians ?? []) {
        for (const asset of custodian.supported_assets) {
          if (asset.ticker && asset.network) {
            // Check if our assets collection already contains
            // this combination
            if (
              assets.find(
                (a) => a.ticker === asset.ticker && a.network === asset.network
              )
            ) {
              continue
            }

            // Add in this token if we haven't already
            if (!(asset.ticker in tokens)) {
              tokens[asset.ticker] = {}
            }

            // Instantiate this network for this ticker if we haven't already
            if (!(asset.network in tokens[asset.ticker])) {
              tokens[asset.ticker][asset.network] = []
            }

            // Add in the custodian for this ticker + network if we haven't already
            if (
              !tokens[asset.ticker][asset.network].find(
                (c) => c.id === custodian.id
              )
            ) {
              tokens[asset.ticker][asset.network].push(custodian)
            }
          }
        }
      }

      // If we already have some assets added lets run through and remove anything that
      // doesn't exist in our new org tokens
      let removedAssets = false
      for (let i = _newAssets.length; i--; ) {
        const asset = _newAssets[i]
        if (
          asset.ticker &&
          asset.ticker.length > 0 &&
          asset.network &&
          asset.network.length > 0 &&
          (!Object.prototype.hasOwnProperty.call(tokens, asset.ticker) ||
            !Object.prototype.hasOwnProperty.call(
              tokens[asset.ticker],
              asset.network
            ))
        ) {
          removedAssets = true
          _newAssets.splice(i, 1)
        }
      }

      // If we removed any assets then we'll show a notification
      if (removedAssets) {
        setNewAssets([..._newAssets])
        if (assets.length <= 0 && _newAssets.length <= 0) {
          addNewAsset([..._newAssets])
        }
        ToastManager.showToast({
          type: 'information',
          text: 'Assets have been removed.',
        })
      }

      setAllTokens(tokens)
    } else {
      // Custodians are null/undefined/empty. Remove any assets
      // if we've already added.
      setNewAssets([])
      if (assets.length <= 0) {
        addNewAsset([])
      }
    }
  }, [custodians])

  useEffect(() => {
    if (!isLoadingAssets && assets.length <= 0) {
      addNewAsset()
    }
  }, [isLoadingAssets])

  const addNewAsset = (existing?: IDigitalAsset[]) => {
    if (!hasIncompleteAssets()) {
      setNewAssets([
        ...(existing ?? _newAssets),
        {
          id: '',
          platform_id: '',
          category: AssetCategory.DIGITAL,
          network: '',
          ticker: '',
          type: DigitalAssetType.ERC20,
          contract_address: '',
          wallet: {
            type: AssetWalletType.WARM,
            source: DigitalWalletSource.GENERATED,
          },
          custodian: {
            provider: '',
          },
          created_at: new Date().toISOString(),
          created_by: '',
          last_updated_at: new Date().toISOString(),
          statuses: [],
        },
      ])
    }
  }

  const removeLastAsset = () => {
    setNewAssets([..._newAssets.slice(0, -1)])
  }

  // Check to see if the new asset is complete and as such
  // a new asset can then be added
  const hasIncompleteAssets = (): boolean => {
    return (
      _newAssets.filter(
        (a) => a.custodian === undefined || a.network === '' || a.ticker === ''
      ).length > 0
    )
  }

  const renderExistingRow = (asset: IDigitalAsset) => {
    return (
      <div
        key={`existing_asset_${asset.id}`}
        className={'flex flex-row self-stretch gap-[8px]'}
      >
        <input
          type={'hidden'}
          name={props.name}
          value={JSON.stringify(asset)}
        />
        <Input.Text
          icon={<Icon crypto={asset.ticker} size={'small'} alt={''} />}
          state={'readonly'}
          value={asset.ticker.toUpperCase()}
          suffix={capitalizeFirstLetter(asset.network)}
          elementClassName={'bg-[#2124271A]'}
        />
        <Input.Text
          icon={
            <Icon
              name={'shield-check'}
              variant={'solid'}
              family={'sharp'}
              size={'medium'}
            />
          }
          state={'readonly'}
          value={asset.custodian?.provider ?? '-'}
          elementClassName={'bg-[#2124271A]'}
        />
      </div>
    )
  }

  const renderNewRow = (asset: IDigitalAsset, index: number) => {
    const hasTickerNetwork =
      asset.ticker &&
      asset.ticker.length > 0 &&
      asset.network &&
      asset.network.length > 0

    const setValue = (
      ticker: string,
      network: string,
      custodian?: ICustodian
    ) => {
      setNewAssets([
        ..._newAssets.map((a, i) => {
          if (i === index) {
            // Set ticker + network as we should have those
            a.ticker = ticker
            a.network = network

            // Check to see if we've either been given a custodian or
            // if theres only 1 to choose from
            const setCustodian =
              custodian ??
              (a.ticker &&
              a.network &&
              _allTokens[asset.ticker][a.network].length === 1
                ? _allTokens[asset.ticker][a.network][0]
                : undefined)

            if (setCustodian) {
              // We have a custodian to set - lets get the asset object first
              const custodianAsset = setCustodian.supported_assets.find(
                (ca) =>
                  ca.network.toLowerCase() === a.network.toLowerCase() &&
                  ca.ticker.toLowerCase() === a.ticker.toLowerCase()
              )

              // Check we found it
              if (custodianAsset) {
                // Set the remaining values
                a.contract_address = custodianAsset.contract_address
                a.type = custodianAsset.contract_address
                  ? DigitalAssetType.ERC20
                  : DigitalAssetType.NATIVE

                // We need to convert the ICustodian object into an IAssetCustodian object
                a.custodian = {
                  provider: setCustodian.name,
                  supported_asset_key: custodianAsset.key,
                  care_of: '',
                  contact: '',
                }
              }
            }
          }
          return a
        }),
      ])
    }

    const onTokenChanged = (item: RowTypes<TickerNetwork> | null) => {
      setNewAssets([
        ..._newAssets.map((a, i) => {
          if (i === index) {
            if (item === null) {
              a.ticker = ''
              a.network = ''
              a.custodian = {
                provider: '',
              }
            } else if ('data' in item && item.data) {
              a.ticker = item.data.ticker
              a.network = item.data.network

              // Check to see if we've either been given a custodian or
              // if theres only 1 to choose from
              const setCustodian =
                _allTokens[a.ticker][a.network].length === 1
                  ? _allTokens[a.ticker][a.network][0]
                  : undefined

              if (setCustodian) {
                // We have a custodian to set - lets get the asset object first
                const custodianAsset = setCustodian.supported_assets.find(
                  (ca) =>
                    ca.network.toLowerCase() === a.network.toLowerCase() &&
                    ca.ticker.toLowerCase() === a.ticker.toLowerCase()
                )

                // Check we found it
                if (custodianAsset) {
                  // Set the remaining values
                  a.contract_address = custodianAsset.contract_address
                  a.type = custodianAsset.contract_address
                    ? DigitalAssetType.ERC20
                    : DigitalAssetType.NATIVE

                  // We need to convert the ICustodian object into an IAssetCustodian object
                  a.custodian = {
                    provider: setCustodian.name,
                    supported_asset_key: custodianAsset.key,
                    care_of: '',
                    contact: '',
                  }
                }
              }
            }
          }
          return a
        }),
      ])
    }

    return (
      <div
        key={`asset_${index}`}
        className={'flex flex-row self-stretch gap-[8px]'}
      >
        <Input.Suggest
          state={'default'}
          icon={
            asset.ticker ? (
              <Icon crypto={asset.ticker} size={'small'} alt={''} />
            ) : (
              <Icon name={'coin'} size={'small'} alt={''} />
            )
          }
          suffix={capitalizeFirstLetter(asset.network ?? '')}
          items={Object.keys(_allTokens)
            .sort((a, b) => a.localeCompare(b))
            .flatMap((ticker) => {
              return Object.keys(_allTokens[ticker]).map((network) => {
                return (
                  <ListItem<TickerNetwork>
                    key={`${ticker}_${network}`}
                    value={`${ticker}:::${network}`}
                    data={{
                      ticker,
                      network,
                    }}
                    leading={<Icon crypto={ticker} size={'small'} alt={''} />}
                    title={ticker.toUpperCase()}
                    trailingLabel={capitalizeFirstLetter(network)}
                    className={'pl-2 pr-2'}
                  />
                )
              })
            })}
          placeholder={t('token')}
          onItemSelectionChange={onTokenChanged}
          addValueAsEntry={false}
        />
        <Input.Dropdown
          state={asset.ticker ? 'default' : 'disabled'}
          value={asset.custodian?.provider ?? ''}
          text={asset.custodian?.provider ?? ''}
          {...(asset.custodian?.provider && {
            icon: (
              <Icon
                name={'shield-check'}
                variant={'solid'}
                family={'sharp'}
                size={'medium'}
              />
            ),
          })}
          items={
            !hasTickerNetwork
              ? []
              : _allTokens[asset.ticker][asset.network].map((custodian) => (
                  <ListItem
                    key={custodian.name}
                    value={custodian.name}
                    title={custodian.name}
                    onClick={() => {
                      setValue(asset.ticker, asset.network, custodian)
                    }}
                    className={'pl-2 pr-2'}
                  />
                ))
          }
          placeholder={t('custodian')}
        />
      </div>
    )
  }

  return (
    <div
      className={twMerge(
        'flex p-[16px] flex-col items-start gap-[16px] self-stretch',
        'bg-[#F5F5F6] rounded-[12px]'
      )}
    >
      <input
        type={'hidden'}
        name={props.name}
        value={JSON.stringify([...assets, ..._newAssets])}
      />
      <div className={'flex flex-col self-stretch gap-[8px]'}>
        <div className={'flex flex-row self-stretch gap-[8px]'}>
          <Input.Component.Label text={t('token')} />
          <Input.Component.Label text={t('custodian')} />
        </div>
        <div className={'flex flex-col self-stretch gap-[16px]'}>
          {assets
            .sort(
              (a, b) =>
                new Date(a.created_at).getTime() -
                new Date(b.created_at).getTime()
            )
            .map((a) => renderExistingRow(a))}
          {_newAssets
            .sort(
              (a, b) =>
                new Date(a.created_at).getTime() -
                new Date(b.created_at).getTime()
            )
            .map((a, i) => renderNewRow(a, i))}
        </div>
      </div>
      <div className={'flex flex-row gap-[16px]'}>
        <Button.Shape
          shape={'square'}
          size={'small'}
          hierarchy={'secondary'}
          layout={'icon'}
          state={hasIncompleteAssets() ? 'disabled' : 'default'}
          icon={{
            name: 'plus',
          }}
          onClick={() => addNewAsset()}
          withAttributes={{
            type: 'button',
          }}
        />
        <Button.Shape
          shape={'square'}
          size={'small'}
          hierarchy={'secondary'}
          layout={'icon'}
          state={
            (assets.length > 0 && _newAssets.length > 0) ||
            (assets.length <= 0 && _newAssets.length > 1)
              ? 'default'
              : 'disabled'
          }
          icon={{
            name: 'minus',
          }}
          onClick={removeLastAsset}
          withAttributes={{
            type: 'button',
          }}
        />
      </div>
    </div>
  )
}
