import { t } from 'i18next'
import { useState } from 'react'

import { INTENTS } from '@/actions/intents'
import Button from '@/components/button'
import { Form } from '@/components/form'
import Input from '@/components/input'
import { ListItem } from '@/components/list-item'
import { Radio } from '@/components/radio'
import { Typography } from '@/components/typography'
import { BannerManager } from '@/contexts/banner'
import { ToastManager } from '@/contexts/toast'
import { ActionResponse } from '@/types/actions'
import { IEscrowUser, IOperationWithConfig } from '@/types/escrows'

import { Drawer } from '../..'
import { BaseProps } from '../types'

type PartyType = 'A' | 'B'

export interface Props extends BaseProps {
  type: 'INITIATOR' | 'APPROVER'
  escrow: IOperationWithConfig
  onUserCreated?: (user: IEscrowUser) => void
}
export const NewEscrowUserDrawer = (props: Props) => {
  const [_submitting, setSubmitting] = useState<INTENTS | null>(null)
  const [selectedParty, setSelectedParty] = useState<PartyType>('A')

  // Clear the submitting flag
  const clearSubmitting = () => setSubmitting(null)

  // On form submission
  const onSubmit = (intent: INTENTS) => setSubmitting(intent)

  // On form exception
  const onException = (actionRes: ActionResponse<IEscrowUser>) => {
    BannerManager.showBanner({
      variant: 'page',
      type: 'error',
      title: actionRes.message ?? t('something_went_wrong'),
    })
    props.onClose()
  }

  // On form submit successfully
  const onSuccess = (actionRes: ActionResponse<IEscrowUser>) => {
    actionRes.data && props.onUserCreated?.(actionRes.data)
    ToastManager.showToast({
      type: 'success',
      text: actionRes.message ?? t('action_successful'),
    })
    props.onClose()
  }

  const onPartyChange = (_id: string, _groupId: string, value: PartyType) => {
    setSelectedParty(value)
  }

  return (
    <Drawer
      title={
        props.type === 'INITIATOR' ? t('new_initiator') : t('new_approver')
      }
      description={t('new_escrow_drawer_description')}
      visible={props.visible}
      onClose={props.onClose}
    >
      <Form<IEscrowUser, INTENTS>
        type={'other'}
        testId={'create-escrow-user-form'}
        buttons={[
          <Button.Basic
            key={'btn_create_escrow_user'}
            label={
              props.type === 'INITIATOR'
                ? t('create_initiator')
                : t('create_approver')
            }
            state={
              _submitting === INTENTS.CREATE_ESCROW_USER
                ? 'loading'
                : _submitting !== null
                  ? 'disabled'
                  : 'default'
            }
            withAttributes={{
              type: 'submit',
              name: 'intent',
              value: INTENTS.CREATE_ESCROW_USER,
            }}
          />,
        ]}
        onSubmit={onSubmit}
        onSuccess={onSuccess}
        onException={onException}
        onFailed={clearSubmitting}
        onUnknownResponse={clearSubmitting}
      >
        <input type={'hidden'} name={'type'} value={props.type} />
        <input
          type={'hidden'}
          name={'config'}
          value={JSON.stringify(props.escrow.config)}
        />

        <Input.Text
          id="user_name"
          name="name"
          label={t('full_name')}
          placeholder={t('full_name_placeholder')}
        />

        <Input.Text
          id="user_email"
          name="email"
          label={t('email')}
          placeholder={t('email_address_placeholder')}
        />

        <Input.Text
          id="phone"
          name="phone"
          label={t('mobile_number')}
          placeholder={t('mobile_number_placeholder')}
        />

        <div className={'flex flex-col gap-[16px] w-full max-w-[375px]'}>
          <Typography variant="label-small">{t('assign_to_party')}</Typography>
          <ListItem
            title={t('party_a')}
            description={props.escrow.config.group_a.name}
            style={'bold'}
            key={`party_a`}
            trailing={
              <Radio<PartyType>
                id={`party_a`}
                value={`A`}
                groupId={'party_group'}
                defaultChecked={selectedParty === 'A'}
                onCheckChanged={onPartyChange}
                size={'medium'}
              />
            }
          />
          <ListItem
            title={t('party_b')}
            description={props.escrow.config.group_b.name}
            style={'bold'}
            key={`party_b`}
            trailing={
              <Radio<PartyType>
                id={`party_b`}
                value={`B`}
                groupId={'party_group'}
                defaultChecked={selectedParty === 'B'}
                onCheckChanged={onPartyChange}
                size={'medium'}
              />
            }
          />
        </div>
      </Form>
    </Drawer>
  )
}
