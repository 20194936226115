import { useQuery } from '@tanstack/react-query'

import { getEscrowWithdrawals } from '@/api/escrows/v1'

export const key = 'escrow-withdrawals'
export const useEscrowWithdrawals = (escrowId: string) => {
  const { isLoading, data } = useQuery({
    queryKey: [key, escrowId],
    queryFn: () => getEscrowWithdrawals(escrowId),
  })
  return { withdrawals: data?.data ?? [], isLoading }
}
