import DropdownController, {
  Props as ControllerProps,
} from '@/components/dropdown/components/controller.dropdown'
import { IOperationWithConfig } from '@/types/escrows'

import AdminEscrowActionsView from './views/admin-escrow-actions.view'

interface Props extends ControllerProps<unknown> {
  escrow: IOperationWithConfig
}
const EscrowActionsController = (props: Props) => {
  return (
    <DropdownController
      {...props}
      defaultViewId={'ADMIN_ESCROW_ACTIONS'}
      closeOnItemClick={true}
    >
      <AdminEscrowActionsView escrow={props.escrow} />
    </DropdownController>
  )
}
export default EscrowActionsController
