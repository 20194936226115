import { t } from 'i18next'

import { updateOrgUser } from '@/api'
import { updateEscrowConfig } from '@/api/escrows/v1'
import { REGEX } from '@/constants/regex'
import { queryClient } from '@/contexts'
import { FormErrorsManager } from '@/contexts/formErrors'
import { IsNot } from '@/helpers/test'
import { key as QueryAllEscrowsKey } from '@/hooks/queries/useAllEscrows'
import { key as QueryEscrowsKey } from '@/hooks/queries/useEscrows'
import { ActionResponse } from '@/types/actions'
import { IEscrowConfig, IEscrowUser } from '@/types/escrows'
import { IUser } from '@/types/user'

export const EditEscrowUser = async (
  formData: FormData
): Promise<ActionResponse<IEscrowUser>> => {
  const id = formData.get('id')?.toString()
  const name = formData.get('name')?.toString()
  const phone = formData.get('phone')?.toString()
  const config = formData.get('config')?.toString()
  const party = formData.get('party_group')?.toString()

  // Check basic details
  if (IsNot(config, REGEX.IS_ANY)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: '',
        error: t('invalid_config_provided'),
      },
    ])
  } else if (IsNot(id, REGEX.IS_ANY)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: '',
        error: t('invalid_id_provided'),
      },
    ])
  } else if (IsNot(name, REGEX.IS_HUMAN_NAME)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'name',
        error: t('invalid_name_provided'),
      },
    ])
  } else if (phone && phone.length > 0 && IsNot(phone, REGEX.IS_PHONE_NUMBER)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'phone',
        error: t('invalid_phone_provided'),
      },
    ])
  }

  // Attempt to parse the original config
  const newConfig: IEscrowConfig = {
    ...(JSON.parse(config) || {}),
  }

  // Find the user in the config
  const foundInGroupA = newConfig.group_a.users?.find((u) => u.id === id)
  const foundInGroupB = newConfig.group_b.users?.find((u) => u.id === id)
  let newUser = foundInGroupA ?? foundInGroupB

  // Check we found the user
  if (!newUser) {
    return FormErrorsManager.addErrors([
      {
        fieldName: '',
        error: t('user_not_found'),
      },
    ])
  }

  // Check if we need to patch the user details
  if (name !== newUser.name || phone !== newUser.phone) {
    // We need to patch the user - start by updating our
    // user object - this requires a little bit of logic as we're
    // playing with an IEscrowUser type but it requires a IUser type
    // -- basically the same but with a few values we need to check
    // are present.
    newUser = {
      // When updating we should have the following but in case we havent
      // we'll use these defaults
      id: '',
      organization_id: '',
      created_at: new Date().toISOString(),

      // We'll overwrite the above at this point using any values in our
      // existing user object
      ...newUser,

      // And then overwrite the following values
      name,
      phone: phone ?? '',

      // Role should never be replaced unless its currently blank or undefined
      role: newUser.role && newUser.role.length > 0 ? newUser.role : 'user',
    } satisfies IUser

    const updateRes = await updateOrgUser(newUser as IUser)

    // Check we patched the user ok
    if (updateRes.error || !updateRes.data) {
      throw new Error(updateRes.message ?? t('something_went_wrong'))
    }
  }

  // Make sure both config groups are initialised
  newConfig.group_a.users ??= []
  newConfig.group_b.users ??= []

  // Check if we need to swap the users party
  let hasChangedGroup = false
  if (foundInGroupA && party === 'B') {
    // We need to move the user to group B
    newConfig.group_a.users = newConfig.group_a.users.filter((u) => u.id !== id)
    newConfig.group_b.users.push(newUser)
    hasChangedGroup = true
  } else if (foundInGroupB && party === 'A') {
    // We need to move the user to group A
    newConfig.group_b.users = newConfig.group_b.users.filter((u) => u.id !== id)
    newConfig.group_a.users.push(newUser)
    hasChangedGroup = true
  }

  // If we've changed the group then we need to update the config
  if (hasChangedGroup) {
    // Update the config
    const configUpdateRes = await updateEscrowConfig(newConfig)

    // Check for errors
    if (configUpdateRes.error) {
      throw new Error(configUpdateRes.message ?? t('something_went_wrong'))
    }
  }

  // Invalidate cache
  await Promise.all([
    queryClient.invalidateQueries({
      queryKey: [QueryEscrowsKey],
    }),
    queryClient.invalidateQueries({
      queryKey: [QueryAllEscrowsKey],
    }),
  ])

  return {
    error: false,
    message: t('user_updated_successfully'),
    completion: 'COMPLETE',
    data: newUser,
  }
}
