export enum INTENTS {
  // Authentication
  AUTHENTICATE = 'authenticate',
  VERIFY_PASSCODE = 'verify-passcode',
  RECOVER_PASSWORD = 'recover-password',
  RESET_PASSWORD = 'reset-password',

  // Assets
  CREATE_ASSET = 'create-asset',
  EDIT_ASSET = 'edit-asset',
  SHARE_ASSET = 'share-asset',
  TRANSFER_ASSET = 'transfer-asset',
  CREATE_ASSET_NOTE = 'create-asset-note',
  EDIT_ASSET_NOTE = 'edit-asset-note',
  DELETE_ASSET_NOTE = 'delete-asset-note',
  ADD_ASSET_DOCUMENT = 'add_asset_document',
  IMPORT_ASSETS = 'import_assets',

  // Defendants
  EDIT_DEFENDANT = 'edit_defendant',
  DELETE_DEFENDANT = 'delete_defendant',
  CREATE_DEFENDANT = 'create_defendant',

  // Reporting
  GENERATE_REPORT = 'generate-report',

  // Cases / Operations
  CREATE_CASE = 'create-case',
  EDIT_CASE = 'edit-case',
  ARCHIVE_CASE = 'archive-case',
  RESTORE_CASE = 'restore-case',
  DELETE_ARCHIVED_CASE = 'delete-archived-case',
  CASE_ADD_EXISTING_ASSETS = 'case-add-existing-assets',
  ADD_OPERATION_DOCUMENT = 'add-operation-document',

  // Workspaces
  CREATE_WORKSPACE = 'create-workspace',
  EDIT_WORKSPACE = 'edit-workspace',
  DELETE_WORKSPACE = 'delete-workspace',
  ADD_WORKSPACE_MEMBER = 'add-workspace-member',

  // Team
  CREATE_USER = 'create-user',
  EDIT_USER = 'edit-user',
  EDIT_USER_LANGUAGE = 'edit-user-language',
  DELETE_USER = 'delete-user',

  // Escrow
  CREATE_ESCROW = 'create-escrow',
  EDIT_ESCROW = 'edit-escrow',
  DELETE_ESCROW = 'delete-escrow',
  CREATE_ESCROW_USER = 'create-escrow-user',
  EDIT_ESCROW_USER = 'edit-escrow-user',
  DELETE_ESCROW_USER = 'delete-escrow-user',
  ESCROW_MARK_READY = 'escrow-mark-ready',
  ESCROW_SUBMIT_FOR_APPROVAL = 'escrow-submit-for-approval',
  ESCROW_REJECT_DOCUMENTS = 'escrow-reject-documents',
  ESCROW_ADD_BLOCKCHAIN_ADDRESS = 'escrow-add-blockchain-address',
  ESCROW_EDIT_APPROVERS = 'escrow-edit-approvers',
  ESCROW_CREATE_FEE = 'escrow-create-fee',
  ESCROW_EDIT_FEE = 'escrow-edit-fee',
  ESCROW_PROGRESS_FEE = 'escrow-progress-fee',
  ESCROW_REJECT_FEE = 'escrow-reject-fee',

  // Organization
  CREATE_ORGANIZATION = 'create-organization',
  EDIT_ORGANIZATION = 'edit-organization',

  // Custodians
  ADD_CUSTODIAN = 'add-custodian',
  EDIT_CUSTODIAN = 'edit-custodian',

  ADD_SUPPORTED_ASSET = 'add-supported-asset',
  CREATE_SUPER_ADMIN_USER = 'create-superadmin-user',
  EDIT_SUPER_ADMIN_USER = 'edit-superadmin-user',

  // Profile
  UPDATE_USER = 'update-user',
  SET_NEW_PASSWORD = 'set-new-password',
}
