import { getClient, getConfig } from '@/api/common'
import { BaseResponse } from '@/types/api'
import { IDigitalAsset } from '@/types/asset'
import {
  IEscrow,
  IEscrowConfig,
  IEscrowFeeCharge,
  IOperationWithConfig,
  IWithdrawal,
} from '@/types/escrows'
import { IOperation } from '@/types/operations'

export type IEscrowStatus = 'PENDING' | 'TRANSFERRED' | 'FAILED'

export interface IUpdateEscrowResponse {
  assets: string[]
  config: IEscrowConfig
  operation: IOperation
}

export const getEscrows = async (): Promise<BaseResponse<IEscrow[]>> => {
  const client = await getClient(getConfig().BROKER_API_URL_V2)
  return client.get<BaseResponse<IEscrow[]>>(`/escrows`).then((response) => {
    return response.data
  })
}

export const getEscrow = async (
  escrow_id: string
): Promise<BaseResponse<IEscrow>> => {
  const client = await getClient(getConfig().BROKER_API_URL_V2)
  return client
    .get<BaseResponse<IEscrow>>(`/escrows/${escrow_id}`)
    .then((response) => {
      return response.data
    })
}

export const updateEscrow = async ({
  escrow,
  destination_addresses,
  required_organization_approvals,
  append_files,
}: {
  escrow: IEscrow
  destination_addresses?: {
    address: string
    asset_id: string
  }[]
  required_organization_approvals?: {
    id: string
    number_of_approvals: number
    require_approval: boolean
  }[]
  append_files?: {
    key: string
    name: string
    type: string
  }[]
}): Promise<BaseResponse<IEscrow>> => {
  const client = await getClient(getConfig().BROKER_API_URL_V2)
  console.log(escrow.organizations)
  return client
    .patch<BaseResponse<IEscrow>>(`/escrows/${escrow.id}`, {
      destination_addresses:
        destination_addresses ??
        escrow.digital_assets
          .filter((a) => a.destination_wallet?.address)
          .map((a) => {
            return {
              address: a.destination_wallet?.address,
              asset_id: a.id,
            }
          }),
      required_organization_approvals:
        required_organization_approvals ??
        escrow.organizations.map((o) => {
          return {
            id: o.id,
            number_of_approvals: o.number_of_approvals ?? 0,
            require_approval: o.require_approval,
          }
        }),
      ...(append_files && { append_files }),
    })
    .then((response) => {
      return response.data
    })
}

export const progressEscrow = async (escrowId: string) => {
  const client = await getClient(getConfig().BROKER_API_URL_V2)
  return client
    .post<BaseResponse<IEscrow>>(`/escrows/${escrowId}/progress`, {})
    .then((response) => response.data)
}

export const rejectEscrow = async (
  escrowId: string,
  { reason }: { reason: string }
) => {
  const client = await getClient(getConfig().BROKER_API_URL_V2)
  return client
    .post<BaseResponse<IEscrow>>(`/escrows/${escrowId}/reject`, { reason })
    .then((response) => response.data)
}

export const progressEscrowFee = async (escrowId: string, feeId: string) => {
  const client = await getClient(getConfig().BROKER_API_URL_V2)
  return client
    .post<
      BaseResponse<IEscrow>
    >(`/escrows/${escrowId}/fees/${feeId}/progress`, {})
    .then((response) => response.data)
}

export const rejectEscrowFee = async (
  escrowId: string,
  feeId: string,
  reason: string
) => {
  const client = await getClient(getConfig().BROKER_API_URL_V2)
  return client
    .post<BaseResponse<IEscrow>>(`/escrows/${escrowId}/fees/${feeId}/reject`, {
      reason,
    })
    .then((response) => response.data)
}

export const createEscrowFee = async ({
  escrowId,
  files,
  charge,
}: {
  escrowId: string
  files: { key: string; name: string; type: string }[]
  charge: IEscrowFeeCharge
}) => {
  const client = await getClient(getConfig().BROKER_API_URL_V2)
  return client
    .post<BaseResponse<IEscrow>>(`/escrows/${escrowId}/fees`, {
      files: files,
      charge,
    })
    .then((response) => response.data)
}

export const updateEscrowFee = async ({
  escrowId,
  feeId,
  files,
  charge,
}: {
  escrowId: string
  feeId: string
  files: { key: string; name: string; type: string }[]
  charge: IEscrowFeeCharge
}) => {
  const client = await getClient(getConfig().BROKER_API_URL_V2)
  return client
    .patch<BaseResponse<IEscrow>>(`/escrows/${escrowId}/fees/${feeId}`, {
      append_files: files,
      charge,
    })
    .then((response) => response.data)
}

/*

  ANYTHING AFTER THIS LINE ARE OLDER ESCROW CALLS
  Unsure if these are still required as we've done a full revamp of
  escrow but havent had the time to check.

*/

export const getAllEscrows = async (): Promise<
  BaseResponse<IOperationWithConfig[]>
> => {
  const client = await getClient(getConfig().GOVERNANCE_API_URL)
  return client
    .get<BaseResponse<IOperationWithConfig[]>>(`/escrows/all`)
    .then((response) => {
      return response.data
    })
}

export const getEscrowAssets = async (
  escrow_id: string,
  organisation_id: string,
  workspace_id: string
): Promise<BaseResponse<IDigitalAsset[]>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL_V2)
  return client
    .get<
      BaseResponse<IDigitalAsset[]>
    >(`/operations/${escrow_id}/assets/digital?organization_id=${organisation_id}&workspace_id=${workspace_id}`)
    .then((response) => {
      return response.data
    })
}

export const getEscrowWithdrawals = async (
  escrow_id: string
): Promise<BaseResponse<IWithdrawal[]>> => {
  const client = await getClient((await getConfig()).GOVERNANCE_API_URL)
  return await client
    .get<BaseResponse<IWithdrawal[]>>(`/withdrawal?escrow_id=${escrow_id}`)
    .then((response) => {
      console.log('withdrawals', response.data)
      return response.data
    })
}

export const createEscrow = async (
  name: string,
  groupAName: string,
  groupBName: string,
  assets: IDigitalAsset[]
): Promise<BaseResponse<IUpdateEscrowResponse>> => {
  const client = await getClient((await getConfig()).GOVERNANCE_API_URL)
  return client
    .post<BaseResponse<IUpdateEscrowResponse>>(`/escrows`, {
      name,
      group_a: {
        name: groupAName,
      },
      group_b: {
        name: groupBName,
      },
      assets,
    })
    .then((response) => {
      console.log('createEscrow res', response)
      return response.data
    })
}

export const rejectEscrowDocuments = async (config: IEscrowConfig) => {
  const client = await getClient((await getConfig()).GOVERNANCE_API_URL)
  return client
    .put<BaseResponse<null>>(`/config`, {
      ...config,
      withdrawal_ready: false,
      documents_ready: false,
    } satisfies IEscrowConfig)
    .then(async (response) => {
      return response.data
    })
}

export const getEscrowConfig = async (
  escrow_id: string
): Promise<BaseResponse<IEscrowConfig>> => {
  const client = await getClient((await getConfig()).GOVERNANCE_API_URL)
  return await client
    .get(`/config/by-escrow?escrow_id=${escrow_id}`)
    .then((response) => {
      console.log('getEscrowConfig res', response.data)
      return response.data
    })
}

export const updateEscrowConfig = async (
  config: IEscrowConfig
): Promise<BaseResponse<IEscrowConfig>> => {
  const client = await getClient((await getConfig()).GOVERNANCE_API_URL)
  console.log('updating config with:', config)
  return await client
    .put<BaseResponse<IEscrowConfig>>(`/config`, config)
    .then(async (response) => {
      // Atm this call doesn't return the latest config, we require this data
      // so we can have correct user id info. Also theres a weird situation where
      // we pass user data up in a 'user' prop but we get it back in a 'user_group'
      // prop... so rather than recreate a new object lets get direct from source
      // This call might get updated so we'll only get from source whilst no data
      // is sent back.
      return {
        ...response.data,
        ...(response.data.data === null && {
          data: (await getEscrowConfig(config.escrow_id)).data,
        }),
      }
    })
}

export const deleteEscrow = async (
  escrowId: string
): Promise<BaseResponse<unknown>> => {
  const client = await getClient((await getConfig()).GOVERNANCE_API_URL)
  return client.delete(`/escrows/${escrowId}`).then((response) => {
    console.log('deleteEscrow res', response.data)
    return response.data
  })
}

export const submitEscrowDocuments = async (
  escrow_id: string,
  files: { key: string; name: string; type: string }[]
): Promise<BaseResponse<IEscrow>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL_V2)
  return client
    .patch(`/escrows/${escrow_id}`, { append_files: files })
    .then((response) => {
      return response.data
    })
}

export const setEscrowWithdrawalReady = async (
  escrow_name: string,
  escrow_id: string
): Promise<BaseResponse<null>> => {
  const client = await getClient((await getConfig()).GOVERNANCE_API_URL)
  return client
    .post(
      `/escrow/withdrawal-ready?escrow_id=${escrow_id}&escrow_name=${escrow_name}`
    )
    .then((response) => {
      return response.data
    })
}
